import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
    IAccount,
    ICentralUserInfo,
    IConfirmEmailData,
    IEmail,
    IInviteCallback,
    IInviteNewUser,
    ILogin,
    InvitedUser,
    IRegistration,
    IResetPassword,
    RegisteredUser
} from '@app/shared/models/api-interfaces.model';
import { Observable, throwError } from 'rxjs';
import { AuthenticationModel } from '@app/core/services/models/authentication.model';
import { AuthManagerService } from '@app/core/services/auth-manager.service';
import { BaseApiService } from './api-helper.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { LocalCacheService } from '@app/common/services/local-cache.service';
import { Router } from '@angular/router';
import { TokenLoginModel } from '@app/core/services/models/token-login.model';

@Injectable()

export class IdentityApiService extends BaseApiService {

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(private authManager: AuthManagerService,
                http: HttpClient,
                private localCacheService: LocalCacheService,
                private router: Router
    ) {
        super(http, environment.identityApiUrl + '/api/');
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    confirmEmail(confirmEmailData: IConfirmEmailData) {
        return this.post(this.accountApi('ConfirmEmail'), confirmEmailData);
    }

    forgotPassword(email: IEmail) {
        return this.post(this.accountApi('ForgotPassword'), email);
    }

    login(login: ILogin): Observable<AuthenticationModel> {
        return this.post(this.accountApi('Login'), login)
            .pipe(
                map(result => {
                    this.authManager.isLoggedIn = true;

                    const authModel = new AuthenticationModel(result.body);
                    this.localCacheService.iCentralApiAccessToken = authModel.token;
                    this.localCacheService.iCentralApiRefreshToken = authModel.refreshToken;
                    return authModel;
                }),
                catchError((error: any) => {
                    this.clearAuthTokens();
                    return throwError(() => error);
                })
            );
    }

    getICentralTokenFromRefreshToken(): Observable<any> {
        return this.post(this.accountApi('token'), new TokenLoginModel(this.localCacheService.iCentralApiRefreshToken))
            .pipe(
                map(result => {
                    this.authManager.isLoggedIn = true;
                    const authModel = new AuthenticationModel(result.body);
                    this.localCacheService.iCentralApiAccessToken = authModel.token;
                    return authModel;
                }),
                catchError((error: any) => {
                    this.clearAuthTokens();
                    this.router.navigate(['/login']);

                    error.status = 401;
                    return throwError(() => error);
                })
            );
    }

    logout() {
        return this.post<any>(this.accountApi('Logout'))
            .pipe(tap(result => {
                this.authManager.isLoggedIn = false;
                this.clearAuthTokens();
            }));
    }

    changeEmail(oldEmail: string, newEmail: string) {
        const body = {
            oldEmail: oldEmail,
            newEmail: newEmail
        };
        return this.put(this.accountApi('UpdateUsersEmail'), body);
    }

    sendRegistration(registration: IRegistration): Observable<HttpResponse<RegisteredUser>> {
        return this.post(this.accountApi('Register'), registration);
    }

    resendConfirmationEmail(email: IEmail) {
        return this.post(this.accountApi('ResendEmailConfirmation'), email);
    }

    resetPassword(resetPasswordData: IResetPassword) {
        return this.post(this.accountApi('ResetPassword'), resetPasswordData);
    }

    getIcentralUserDetails(userId?: string, query?: string) {
        return this.get<ICentralUserInfo>(this.ICentralUserDetailsApi(userId, query))
            .pipe(tap(result => {
                this.authManager.isLoggedIn = true;
            }));
    }

    getInvitedUserDetails(userId: string): Observable<HttpResponse<InvitedUser>> {
        return this.get<InvitedUser>(this.getApiAppend(this.accountApi('InvitedUser'), userId));
    }

    inviteNewUser(account: IAccount, email: string) {

        const postBody = {
            accountId: account.accountID,
            accountName: account.accountName,
            email: email,
            senderEmail: account.mainContact.email
        } as IInviteNewUser;

        return this.post(this.accountApi('SendInviteEmail'), postBody);
    }

    inviteCallback(token: string, userId: string, accountId: string): Observable<any> {
        const body = {
            token: token,
            userId: userId,
            accountId: accountId
        } as IInviteCallback;
        return this.post(this.accountApi('InviteCallback'), body);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private accountApi(endpoint?: string): string {
        if (endpoint) {
            endpoint = 'Account/' + endpoint;
        } else {
            endpoint = 'Account';
        }
        return this.getApiCall(endpoint);
    }

    private ICentralUserDetailsApi(userId?: string, query?: string): string {
        return this.getApiCall('Account', userId, query);
    }

    private clearAuthTokens() {
        this.localCacheService.iCentralApiAccessToken = null;
        this.localCacheService.iCentralApiRefreshToken = null;
    }
}
