import { BehaviorSubject, filter, Observable, switchMap, take, throwError } from 'rxjs';
import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpStatusCode, HttpUserEvent,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppSyncService } from '@app/common/services/app-sync.service';
import { AuthenticationModel } from '@app/core/services/models/authentication.model';
import { AuthManagerService } from '@app/core/services/auth-manager.service';
import { BlankMessage } from '@app/shared/models/blank-message.model';
import { catchError } from 'rxjs/operators';
import { DataStorage } from '@app/core/services/data-storage.service';
import { IcentralError } from '@app/shared/models/api-interfaces.model';
import { IdentityApiService } from '@app/core/services/identity.service';
import { LocalCacheService } from '@app/common/services/local-cache.service';
import { Router } from '@angular/router';

@Injectable()
export class CentralHttpInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private authManager: AuthManagerService,
                private dataStore: DataStorage,
                private injector: Injector,
                private router: Router,
                private localCacheService: LocalCacheService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const accessToken = this.localCacheService.iCentralApiAccessToken;
        let copiedRequest = req;

        // Send the AppSync Channel Name in the header.
        copiedRequest = copiedRequest.clone({headers: copiedRequest.headers.append('AppSync-Channel', AppSyncService.channelName)});

        if (accessToken && accessToken !== '') {
            copiedRequest = copiedRequest.clone({
                headers: copiedRequest.headers
                    .append('Authorization', `bearer ${accessToken}`)
            });
        }

        return next.handle(copiedRequest)
            .pipe(
                catchError((error: Response, caught: Observable<any>) => {
                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            case HttpStatusCode.Unauthorized:
                                return this.handle401Error(req, next);
                            default:
                                this.handleError(error);
                        }

                        // Allow our customized errors to be returned.
                        if ((<any>error)?.error?.error_1 && (<any>error)?.error?.error_1[0] != null) {
                            return throwError(() => error);
                        }

                        return throwError(() => caught);
                    } else {
                        return throwError(() => error);
                    }
                })
            );
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        // Handle standard API missing or expired token.
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const identityApiService = this.injector.get(IdentityApiService);

            return identityApiService.getICentralTokenFromRefreshToken()
                .pipe(
                    switchMap((authModel: AuthenticationModel) => {
                        this.tokenSubject.next(authModel.token);
                        const copiedRequest = req.clone({ headers: req.headers.append('Authorization', `bearer ${authModel.token}`) });

                        this.isRefreshingToken = false;
                        return next.handle(copiedRequest);
                    })
                );
        } else {
            return this.tokenSubject
                .pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        const copiedRequest = req.clone({ params: req.params.append('Authentication', token) });
                        return next.handle(copiedRequest);
                    })
                );
        }
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        let iCentralError = error.error as IcentralError;
        let autoNav = '';

        if (!error.ok) {

            const message = {
                pageTitle: `Error: ${error.status}`,
                messageTitle: '',
                messageBody: `${errorMessage}`
            } as BlankMessage;

            switch (error.status) {
                case 400:
                    if (!error.error) { break; }

                    switch (error.error.code) {
                        case 10006:
                            message.pageSubtitle = 'Invalid password/Expired Link';
                            message.messageBody = iCentralError ? iCentralError.detail : 'Invalid password/Expired Link';
                            break;
                        case 10011:
                            autoNav = '/home/admin';
                            break;
                    }
                    break;
                case 401:
                    this.authManager.isLoggedIn = false;
                    message.pageSubtitle = 'Unauthorized';
                    message.messageBody = iCentralError ? iCentralError.detail : 'Invalid Username / Password';
                    autoNav = '/login/';
                    break;
                case 403:
                    message.pageSubtitle = 'Forbidden';
                    message.messageBody = iCentralError ? iCentralError.detail : 'The resource is not available with current credentials.';
                    break;
                case 404:
                    message.pageSubtitle = 'Not Found';
                    message.messageBody = `The page you requested could not be found, either contact your webmaster or try again.
                                Use your browsers BACK button to navigate to the page you have previously come from.`;
                    break;
                case 500:
                    message.pageSubtitle = 'Internal Server Error';
                    message.messageTitle = 'Technical Error';
                    message.messageBody = 'We are working hard to correct this issue. Please wait a few moments and try again.';
                    break;
                default:
                    break;
            }

            if (iCentralError && error.status != 500) {
                iCentralError.status = error.status;
                message.messageBody = iCentralError.detail;
                message.messageTitle = `ICentral Server Error: ${iCentralError.code}`;
            } else {
                iCentralError =
                    {
                        status: error.status
                    } as IcentralError;
            }

            this.dataStore.storeError(iCentralError);
            this.dataStore.storeData(message);
            if (autoNav.length != 0) {
                this.router.navigate([autoNav]);
            }
            return throwError(() => error);

        } else {
            // server-side error
            // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            return;
        }
    }

}
