import {
    Account,
    AerisDataSessionRes,
    AerisDeviceIdRes,
    CheckDataSessionReq,
    CheckReRegistrationReq,
    CheckReRegistrationRes,
    IAccount,
    ICommunicationTest,
    IControllerDetails,
    IControllerModelDetail,
    IDashboard,
    IDashboardAccount,
    IDashboardController,
    IDashboardFinances,
    IManualControllerProgram,
    IManualControllerStation,
    INetworkPerformance,
    InvitedUser,
    IsrCalenderEvent,
    IsrProgram,
    IsrProgramDetails,
    IUser,
    IUserPreferences
} from '@app/shared/models/api-interfaces.model';
import { AutoLearnAccountController, AutoLearnStation } from '@app/shared/models/command-api-interfaces.model';
import { ControllerPermissions, DashboardReportType } from '@app/shared/models/icentral-constants.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseApiService } from './api-helper.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()

export class ApiService extends BaseApiService {
    constructor(http: HttpClient) {
        super(http, environment.accountApiUrl + '/api/');
    }

    /*
     * !!! ACCOUNTS !!!
     */

    /**
     * Generates the route to the accounts related resources.
     * @param {string} id Optional ID to reference Account resource.
     * @param {string} query Optional query for selecting and expanding resource properties.
     * @returns {string} Full api route to resource.
     * @example /api/Accounts/{id}
     */
    private accountsApi(id?: string, query?: string): string {
        return this.getApiCall('Accounts', id, query);
    }


    /** GET /api/Accounts
     *
     * Performs request for Accounts to API
     * @param query Optional query to perform on response body.
     */
    getAccounts(query = '') {
        return this.get<IAccount[]>(this.accountsApi(undefined, query));
    }

    getAccountsByOrgId() {
        const uri = this.joinApiCalls(this.accountsApi(), 'organization');
        return this.get<IAccount[]>(uri);
    }

    /** POST /api/Accounts
     *
     * Performs create account request to API.
     * @param account Account body to create.
     */
    createAccount(account: IAccount) {
        return this.post(this.accountsApi(), account);
    }

    /** GET /api/Accounts/{id}
     *
     * Performs request for specific Account to API
     * @param id GUID of the account
     * @param query Optional query to perform on response body.
     */
    getAccountById(id: string, query = '') {
        return this.get<IAccount>(this.accountsApi(id, query));
    }

    /** PUT /api/Accounts/{id}
     *
     * Performs update account request to API.
     * @param id GUID of account to update.
     * @param account Account body to updaate.
     */
    updateAccount(id: string, account: Account) {
        return this.put(this.accountsApi(id), account);
    }

    /**
     * Generates the route to the subaccountaccount related resources.
     * @param {string} id Optional ID to reference Account resource.
     * @param {string} query Optional query for selecting and expanding resource properties.
     * @returns {string} Full api route to resource.
     * @example /api/Accounts/SubAccount/{id}
     */
    private subAccountApi(id?: string, query?: string): string {
        return this.joinApiCalls(this.accountsApi(), this.getApiAppend('SubAccount', id, query));
    }

    /** DELETE /api/Accounts/{id}
     *
     * Performs delete account request to API.
     * @param id GUID of account of which to delete.
     */
    deleteSubAccount(id: string): Observable<any> {
        return this.delete(this.subAccountApi(id));
    }

    /**
     * Generates the route to the mainaccount related resources.
     * @param {string} id Optional ID to reference Account resource.
     * @param {string} query Optional query for selecting and expanding resource properties.
     * @returns {string} Full api route to resource.
     * @example /api/Accounts/MainAccount/{id}
     */
    private mainAccountApi(id?: string, query?: string): string {
        return this.joinApiCalls(this.accountsApi(), this.getApiAppend('MainAccount', id, query));
    }

    /** DELETE /api/Accounts/MainAccount/{id}
     *
     * Performs delete main account request to API.
     * @param id GUID of account of which to delete.
     */
    deleteMainAccount(id: string): Observable<any> {
        return this.delete(this.mainAccountApi(id));
    }

    /** PUT /api/Accounts/MainAccount/{id}/Reactivate
     *
     * Performs reactivate main account request to API.
     * @param id GUID of account of which to reactivate.
     */
    reactivateMainAccount(id: string): Observable<any> {
        return this.put(this.joinApiCalls(this.mainAccountApi(id), 'Reactivate'));
    }


    /**
     * Generates the route to move an account to a new parent account.
     * @param {string} id The GUID of the account to move.
     * @param {string} newParentId The GUID of the new parent to move to.
     * @returns {string} Full api route that moves an account to new parent.
     * @example /api/Accounts/{accountId}/Parent/{newParentAccountId}
     */
    private moveAccountApi(id: string, newParentId: string): string {
        return this.joinApiCalls(this.accountsApi(id), this.getApiAppend('Parent', newParentId));
    }


    /** PUT /api/Accounts/{accountId}/Parent/{newParentAccountId}
     *
     * Performs move account request to API.
     * @param accountId GUID of account to move. (Child)
     * @param newParentId GUID of account to move to.(Parent)
     */
    moveSubaccount(accountId: string, newParentId: string) {
        return this.put(this.moveAccountApi(accountId, newParentId));
    }



    /**
     * Generates the route to move an account to a new parent account.
     * @param {string} id The GUID of the account to move.
     * @param {string} newParentId The GUID of the new parent to move to.
     * @returns {string} Full api route that moves an account to new parent.
     * @example /api/Accounts/{accountId}/Parent/{newParentAccountId}
     */
    private moveAccountAdminApi(id: string, newParentId: string): string {
        return this.joinApiCalls(this.accountsApi(id), this.getApiAppend('Parent', newParentId), 'Admin');
    }

    /** PUT /api/Accounts/{accountId}/Parent/{newParentAccountId}/Admin
     *
     * Performs move account request to API.
     * @param accountId GUID of account to move. (Child)
     * @param newParentId GUID of account to move to.(Parent)
     */
    moveSubaccountAdmin(accountId: string, newParentId: string) {
        return this.put(this.moveAccountAdminApi(accountId, newParentId));
    }

    /**
     * Generates route to family resource of an account.
     * @param id GUID of account to get family of.
     * @returns Full api route to generate family resource of an account.
     * @example /api/Accounts/{accountId}/AccountFamily
     */
    private accountFamilyApi(id: string, query?: string): string {
        return this.joinApiCalls(this.accountsApi(id), this.getApiAppend('AccountFamily', undefined, query));
    }

    /** GET /api/Accounts/{accountId}/AccountFamily
     *
     * Peforms account family request to API.
     * @param accountId GUID of account to get account family for.
     * @param query Optional query to perform on response body.
     */
    getAccountFamily(accountId: string, query = '') {
        return this.get<IAccount[]>(this.accountFamilyApi(accountId, query));
    }

    /**
     * Generates route to account controllers resource.
     * @param accountId GUID of account to get controllers of.
     * @param query Optional query for selecting and expanding response body.
     * @example /api/Accounts/{accountId}/Controllers
     */
    private controllersOfAccountApi(accountId: string, query?: string): string {
        return this.joinApiCalls(this.accountsApi(accountId), this.getApiAppend('Controllers', undefined, query));
    }

    /** GET /api/Accounts/{accountId}/Controllers
     * Performs request to API to get controllers of account.
     * @param accountId GUID of account to get controllers of.
     * @param query Optional query to perform on response body.
     */
    getControllersFromAccount(accountId: string, query = '') {
        return this.get<IControllerDetails[]>(this.controllersOfAccountApi(accountId, query));
    }

    /**
     * Generates route to account users resource.
     * @param accountId GUID of account to get users of.
     * @param userId Optional userId to specify user of.
     * @param query Optional query to perform on response body.
     * @example /api/Accounts/{accountId}/Users/{userId}
     */
    private usersOfAccountApi(accountId: string, userId?: string, query?: string): string {
        return this.joinApiCalls(this.accountsApi(accountId), this.getApiAppend('Users', userId, query));
    }



    /** POST /api/Accounts/{accountId}/Users/{userId}
     *
     * Performs request to API to add user to account.
     * @param accountId GUID of account of which to add a user to.
     * @param userId GUID of user to add to the account.
     */
    addUserToAccount(accountId: string, userId: string) {
        return this.post(this.usersOfAccountApi(accountId, userId));
    }


    /** GET /api/Accounts/{accountId}/Users/{userId}
     *
     * Performs request to API to get account user resource.
     * @param accountId Account GUID of which to get the information on.
     * @param userId The user GUID to get the information for.
     * @param query Optional query to perform on response body.
     */
    getUserAccountInfo(accountId: string, userId: string, query = '') {
        return this.get<IUser>(this.usersOfAccountApi(accountId, userId, query));
    }


    /** DELETE /api/Accounts/{accountId}/Users/{userId}
     *
     * Performs request to API to remove user from account.
     * @param accountId GUID of account of which to remove a user from.
     * @param userId GUID of user to remove from the account.
     */
    removeUserFromAccount(userId: string, accountId: string) {
        return this.delete(this.usersOfAccountApi(accountId, userId));
    }


    /**
     * Generates route to user family resource of an account.
     * @param id GUID of account to get user family of.
     * @returns Full api route to generate user family resource of an account.
     * @example /api/Accounts/{accountId}/UserFamily
     */
    private accountUserFamilyApi(id: string, query?: string): string {
        return this.joinApiCalls(this.accountsApi(id), this.getApiAppend('UserFamily', undefined, query));
    }


    /** GET /api/Accounts/{accountId}/UserFamily
     *
     * Peforms account user family request to API.
     * @param accountId GUID of account to get user family for.
     * @param query Optional query to perform on response body.
     */
    getAccountUserFamily(accountId: string, query?: string) {
        return this.get<IUser[]>(this.accountUserFamilyApi(accountId, query));
    }

    /** GET /api/Accounts/{accountId}/Users
     *
     * Performs API request to get users resource of account.
     * @param accountId GUID of account to get users from.
     * @param query Optional query to perform on response body.
     */
    getAccountUsers(accountId: string, query: string = '') {
        return this.get<IUser[]>(this.usersOfAccountApi(accountId, undefined, query));
    }

    /** GET /api/Accounts/{accountId}/invitedUsers
     *
     * Performs API request to gets all invited users for a given account.
     * @param accountId GUID of account to get users from.
     */
    getAccountInvitedUsers(accountId: string): Observable<HttpResponse<InvitedUser[]>> {
        return this.get<InvitedUser[]>(this.joinApiCalls(this.accountsApi(accountId), 'invitedUsers'));
    }


    /*
     * !!! AERIS !!!
     */

    /**
     * Generates the route to the accounts related resources.
     * @param {string} id Optional GUID to reference Controller resource.
     * @param {string} query Optional query for selecting and expanding resource properties.
     * @returns {string} Full api route to resource.
     * @example /api/Aeris/{id}
     */
    private aerisApi(id?: string, query?: string): string {
        return this.getApiCall('Aeris', id, query);
    }

    /** GET /api/Aeris/{id}
     *
     * To get IMSI using controller ID
     */
    getAerisDeviceImsi(id: string): Observable<HttpResponse<AerisDeviceIdRes>> {
        return this.get<AerisDeviceIdRes>(this.aerisApi(id));
    }

    checkDataSession(req: CheckDataSessionReq[]): Observable<HttpResponse<AerisDataSessionRes[]>> {
        return this.post(this.joinApiCalls(this.aerisApi(), "DataSession"), req);
    }

    /** DELETE /api/Aeris/ClearRegistration/{imsi}
     *
     * Clear the device registration to force it to re-register.
     */
    clearRegistration(imsi: string): Observable<HttpResponse<any>> {
        return this.delete(this.joinApiCalls(this.aerisApi(), "ClearRegistration", imsi));
    }

    /** POST  /api/Aeris/CheckReRegistration
     *
     * /api/Aeris/CheckReRegistration
     */
    checkReRegistration(req: CheckReRegistrationReq[]): Observable<HttpResponse<CheckReRegistrationRes[]>> {
        return this.post(this.joinApiCalls(this.aerisApi(), "CheckReRegistration"), req);
    }

    /*
     * !!! CONTROLLERS !!!
     */

    /**
     * Generates endpoint to access Controller resources.
     * @param {string} id Optional GUID to reference Controller resource.
     * @param {string} query Optional query for selecting and expanding resource properties.
     * @returns {string} Full api route to resource.
     * @example /api/Controllers/{id}
     */
    private controllersApi(id?: string, query?: string): string {
        return this.getApiCall('controllers', id, query);
    }

    // TODO this should be temporary. Used in temporary dropdown for account selection to test
    getAccountControllers() {
        return this.get<any>(this.joinApiCalls(this.controllersApi(), 'accountcontrollers'));
    }

    /** GET /api/Controllers/{id}
     *
     * Performs API request to get controller resource.
     * @param controllerId GUID of controller to get.
     * @param query Optional query to perform on response body.
     */
    getController(controllerId: string, query = '') {
        return this.get<IControllerDetails>(this.controllersApi(controllerId, query));
    }

    /** PUT /api/Controllers/{id}
     *
     * Performs API request to update controller resource.
     * @param controller Controller body of which to update.
     */
    updateController(controller: IControllerDetails) {
        return this.put(this.controllersApi(controller.id), controller);
    }

    /** DELETE /api/Controllers/{id}
     *
     * Performs API request to delete controller resource. (soft delete)
     * @param controller Controller body of which to soft delete.
     */
    deleteController(controller: IControllerDetails) {
        return this.delete(this.controllersApi(controller.id));
    }


    /** POST /api/Controllers/
     *
     * Performs API request to add controller resource.
     * @param controller Controller body of which to add.
     */
    addController(controller: IControllerDetails) {
        return this.post(this.controllersApi(), controller);
    }

    /**
     * Generates endpoint to access ControllerDetails resource.
     * @param query Optional query to perofrm on response body.
     * @example /api/Controllers/Details
     */
    private controllerDetailsApi(query?: string): string {
        return this.joinApiCalls(this.controllersApi(), this.getApiAppend('Details', undefined, query));
    }

    /** GET /api/Controllers/Details
     *
     * Performs API request to get controller details resources.
     * @param query Optional query to perform on response body.
     */
    getControllerDetails(query: string = '') {
        return this.get<IControllerModelDetail[]>(this.controllerDetailsApi(query));
    }

    /**
     * Generates endpoint to perform move controller.
     * @param controllerId GUID of controller of which to move.
     * @param newAccountId GUID of which to move the controller to.
     * @example /api/Controllers/{controllerId}/Account/{newAccountId}
     */
    private moveControllerToNewAccountApi(controllerId: string, newAccountId: string): string {
        return this.joinApiCalls(this.controllersApi(controllerId), this.getApiAppend('Account', newAccountId));
    }

    /** PUT /api/Controllers/{controllerId}/Account/{newAccountId}
     *
     * Performs API request to move controller to another account.
     * @param controllerId GUID of controller of which to move.
     * @param accountId GUID of account of which to move controller to.
     */
    moveController(controllerId: string, accountId: string) {
        return this.put<any>(this.moveControllerToNewAccountApi(controllerId, accountId));
    }

    private getAccountControllerPermissionsApi(id: string, query?: string): string {
        return this.joinApiCalls(this.controllersApi(id), this.getApiAppend('ControllerPermissions', undefined, query));
    }

    getAccountControllerPermissions(accountId: string, query: string = '') {
        return this.get<ControllerPermissions>(this.getAccountControllerPermissionsApi(accountId, query));
    }

    getControllerNetworkPerformance(controllerId: string, month, year) {
        return this.get<INetworkPerformance[]>(this.joinApiCalls(this.controllersApi(controllerId), month, year));
    }


    /*
     * !!! DASHBOARD !!!
     */


    /**
     * Generates endpoint to perform acacount dashboard.
     * @param accountId GUID of account to get dashboard events for.
     * @example /api/Dashboard/{accoundId}
     */
    private dashboardApi(accountId?: string): string {
        return this.getApiCall('dashboard', accountId);
    }

    /** GET /api/Dashboard/{accoundId}
     *
     * Performs API request to get dashboard events.
     */
    getDashboard(accountId: string) {
        return this.get<IDashboard>(this.dashboardApi(accountId));
    }


    /** GET /api/Dashboard/{organizationId}
     *
     * Performs API request to get dashboard events.
     */
    getDashboardByOrg(orgId: number) {
        return this.get<IDashboard>(this.dashboardApi(orgId.toString()));
    }

    /**
     * Generates endpoint to get accounts presented on the dashboard.
     * @param query Optional query to perform on response body.
     * @example /api/Dashboard/Accounts
     */
    private dashboardAccountsApi(query?: string): string {
        return this.joinApiCalls(this.dashboardApi(), this.getApiAppend('Accounts', undefined, query));
    }

    /** GET /api/Dashboard/Accounts
     *
     * Performs API request to get dashboard accounts.
     * @param query Optional query to perform on response body.
     */
    dashboardAccounts(query?: string) {
        return this.get<IDashboardAccount[]>(this.dashboardAccountsApi(query));
    }

    /**
     * Generates endpoint to get controllers presented on the dashboard.
     * @param query Optional query to perform on response body.
     * @example /api/Dashboard/Controllers
     */
    private dashboardControllersApi(query?: string): string {
        return this.joinApiCalls(this.dashboardApi(), this.getApiAppend('Controllers', undefined, query));
    }

    /** GET /api/Dashboard/Controllers
     *
     * Performs API request to get dashboard controllers.
     * @param query Optional query to perform on response body.
     */
    dashboardControllers(query?: string) {
        return this.get<IDashboardController[]>(this.dashboardControllersApi(query));
    }

    /**
     * Generates endpoint to get users presented on the dashboard.
     * @param query Optional query to perform on response body.
     * @example /api/Dashboard/Users
     */
    private dashboardUsersApi(query?: string): string {
        return this.joinApiCalls(this.dashboardApi(), this.getApiAppend('Users', undefined, query));
    }


    /** GET /api/Dashboard/Users
     *
     * Performs API request to get dashboard users.
     * @param query Optional query to perform on response body.
     */
    dashboardUsers(query?: string) {
        return this.get<IUser[]>(this.dashboardUsersApi(query));
    }

    /**
     * Generates endpoint to send email from the dashboard.
     * @example /api/Dashboard/Reports/{reportId}
     */
    private dashboardReportApi(reportType: DashboardReportType, query?: string): string {
        return this.joinApiCalls(this.dashboardApi(), this.getApiAppend('Report', reportType.toString(), query));
    }

    /** GET api/dashboard/report
     * Performs API request to email dashboard report
     * @param reportId ID of report to get email from
     */
    dashboardReport(reportType: DashboardReportType, query?: string) {
        return this.get(this.dashboardReportApi(reportType, query));
    }

    /**
     * Generates endpoint to send email from the dashboard.
     * @example /api/Dashboard/Finance
     */
    private dashboardFinanceApi(query?: string): string {
        return this.joinApiCalls(this.dashboardApi(), this.getApiAppend('Finance', undefined, query));
    }

    /** GET api/dashboard/finance
     * Performs API request to get dashboard finances
     */
    dashboardFinance() {
        return this.get<IDashboardFinances>(this.dashboardFinanceApi());
    }


    /*
     * !!! USER PREFERENCES !!!
     */


    /**
     * Generates endpoint to for UserPreferences resource.
     * @example /api/UserPreferences.
     */
    private userPreferenceApi(): string {
        return this.getApiCall('UserPreferences');
    }

    /** GET /api/UserPreferences
     *
     * Performs API request to get user preferences.
     */
    getUserPrefrences() {
        return this.get<IUserPreferences>(this.userPreferenceApi());
    }

    /** PUT /api/UserPreferences
     *
     * Performs API request to update user preferences.
     * @param preferences User preferences body to update.
     */
    putUserPreferences(preferences: IUserPreferences) {
        return this.put<IUserPreferences>(this.userPreferenceApi(), preferences);
    }

    /** PUT /api/UserPreferences/DefaultAccount/{accountId}
     *
     * Performs API request to update user preferences' default account.
     * @param accountId GUID of account to update user prefences' default account to.
     */
    updatePreferredAccount(accountId: string) {
        return this.put<any>(this.joinApiCalls(this.userPreferenceApi(), this.getApiAppend('DefaultAccount', accountId)));
    }


    /*
     * !!! USERS !!!
     */

    private usersApi(userId?: string): string {
        return this.getApiCall('Users', userId);
    }


    /// api/Users/{accountId}/Admin
    makeUserAdmin(accountId: string, user: IUser) {
        return this.put(this.joinApiCalls(this.usersApi(accountId), this.getApiAppend('Admin')), user);
    }

    updateUser(accountId: string, user: IUser): Observable<HttpResponse<any>> {

        return this.put(this.usersApi(accountId), user);
    }

    /*
     * !!! COMMUNICATION TEST !!!
     */

    private communicationTestsApi(): string {
        return this.getApiCall('Controllers/AccountControllers');
    }

    /** GET /api/Controllers/AccountControllers
     *
     * Performs API request to get communication test.
     */
    getCommunicationTest() {
        return this.get<ICommunicationTest[]>(this.communicationTestsApi());
    }

    /*
    * !!! STATION START STOP !!!
    */

    /** GET /api/Controllers/{controllerId}/Stations
     *
     * Performs API request to get list of station.
     */
    getManualControllerStations(controllerId: string) {
        const uri = this.joinApiCalls(this.controllersApi(), controllerId, 'Stations');
        return this.get<IManualControllerStation[]>(uri);
    }

    /** GET /api/Controllers/{controllerId}/Programs
     *
     * Performs API request to get list of program.
     */
    getManualControllerPrograms(controllerId: string) {
        const uri = this.joinApiCalls(this.controllersApi(), controllerId, 'Programs');
        return this.get<IManualControllerProgram[]>(uri);
    }

    /* !!! AUTO LEARN !!! */


    /** GET /api/Controllers/AccountControllers
     *
     * Performs API request to get communication test.
     */
    getAutoLearnControllers() {
        return this.get<AutoLearnAccountController[]>(this.communicationTestsApi());
    }

    /** GET /api/Controllers/{controllerId}/Stations
     *
     * Performs API request to get list of station.
     */
    getAutoLearnStations(controllerId: string) {
        const uri = this.joinApiCalls(this.controllersApi(), controllerId, 'Stations');
        return this.get<AutoLearnStation[]>(uri);
    }

    /*
    * !!! REPORTS !!!
    */

    /**
     * Generates the route to the accounts related resources.
     * @param {string} epName Optional End Point name.
     * @param {string} id Optional GUID to reference Controller resource.
     * @param {string} query Optional query for selecting and expanding resource properties.
     * @returns {string} Full api route to resource.
     * @example /api/Reports/{id}
     */
    private reportsApi(epName?: string, id?: string, id2?: string): string {
        const epPartialUrl = epName !== null ? `Reports/${epName}` : 'Reports';
        return id2 == null ?
            `${this.getApiCall(epPartialUrl, id)}` :
            `${this.getApiCall(epPartialUrl, id)}/${id2}`;
    }


    /** GET /api/Reports/events/{id}
     *
     * Performs API request to get list of program events for past 3 months for controller.
     */
    getProgramEventList(controllerId: string): Observable<HttpResponse<IsrCalenderEvent[]>> {
        return this.get<IsrCalenderEvent[]>(this.reportsApi('events', controllerId));
    }

    /** GET /api/Reports/programs/{id}
     *
     * Performs API request to get list of programs for controller.
     */
    getProgramList(controllerId: string): Observable<HttpResponse<IsrProgram[]>> {
        return this.get<IsrProgram[]>(this.reportsApi('programs', controllerId));
    }

    /** GET /api/Reports/program/{Id}/{programId}
     *
     * Performs API request to the program detail.
     */
    getProgramDetails(controllerId: string, programId: string): Observable<HttpResponse<IsrProgramDetails>> {
        return this.get<IsrProgramDetails>(this.reportsApi('program', controllerId, programId));
    }
}
